* {
  padding: 0;
  margin: 0;
}

p, span {
  margin-bottom: 0rem!important;
}

a {
  text-decoration: none!important;
}

/* html {
  font-size: calc(100vw/1920 * 10);
} */

@font-face {
  font-family: 'Neu';
  src: url('./assets/NeueMontreal-Regular.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'Neu';
  src: url('./assets/NeueMontreal-Medium.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'Neu';
  src: url('./assets/NeueMontreal-Bold.otf') format('opentype');
  font-weight: 600;
}


/* @font-face {
  font-family: 'Helv';
  src: url('./assets/_H_HelveticaNeueInterface_0.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Helv';
  src: url('./assets//_H_HelveticaNeueInterface_2.ttf') format('truetype');
  font-weight: medium;
  font-style: normal;
} */

body {
  font-family: 'Neu' , serif!important;
}

/* html {
  font-size: calc(100vw/1920 * 10);
} */