@import './variable.scss';

.about-leader {
    width: 100%;
    padding: 90px 0;
    background-color: $white;
    .al-wrapper {
        background-color: $dark;
        margin: auto;
        width: 100%;
        // height: 80vh;
        height: 650px;
        display: flex;
        position: relative;
        .al-img {
            position: relative;
            width: 50%;
            height: 100%;
            img {
                top: 0;
                left: 0;
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            
        }

        .al-content {
            width: 50%;
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: space-between;
            padding: 30px 60px;

            h2 {
                color: $white;
                padding-bottom: 20px;
                font-size: 32px;
                font-weight: 400;
            }

            p {
                color: rgba(255,255,255, 0.7);
                font-size: 18px;
                line-height: 1.3;
                padding-bottom: 15px;
                font-weight: 400;
                span {
                    color: $white;
                }
            }
        }

        .al-content-two{
            padding-top: 30px;
            border-top: 1px solid rgba(255,255,255, 0.3);
            h2 {
                font-size: 22px;
                padding-bottom: 10px;

            }

            p{
                font-size: 16px;
                line-height: 1.3;
            }
        }
    }
}
