@import './variable.scss';

.blog-content {
    background-color: $bg;
    img {
        width: 100%;
    }

    &-wrapper {
        padding: 60px 0;
        h2 {
            font-size: 16px;
            color: $dark;
        }
        h3 {
            color: $accent;
            font-size: 14px;
        }
    }

    &-main {
        p{
        padding-bottom: 30px;
        font-size: 18px;
        line-height: 1.3;
        color: $dark;
        }
    }
}