@import './variable.scss';

.about {
    padding: 90px 0;
    width: 100%;
    border-bottom: 1px solid rgba(2, 14, 18, 0.1);
    background-color: $white;
}

.about-title {
    h4{
        font-size: 46px;
        font-weight: 400;
    }

   
}

.about-content {
    padding: 0 30px 0 0;
    p{
        font-size: 18px;
        line-height: 1.3;
        // padding-bottom: 30px;

        &:first-child, &:nth-child(2) {
            padding-bottom: 30px;
        }
        
    }
}

.about-articles {
    padding: 90px 0 0 0 ;
}

@media screen and (max-width: 768px){
    .about {
        padding: 60px 0;
    }

    .about-title h4{
        font-size: 32px;
        padding-bottom: 15px;
    }

    .about-content p{
        font-size: 16px;
    }
}