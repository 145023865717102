.layout {
    padding: 0 60px;
    margin: auto;
    // max-width: 110rem;
    max-width: 1800px;
}
@media screen and (max-width: 768px) {
    .layout {
        padding: 0 25px;
    }
}