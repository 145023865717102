@import './variable.scss';

.insights {
    background-color: $bg;
    padding: 90px 0;
    .inisghts-wrapper {
        display: flex;
        width: 100%;
        padding-bottom: 90px;
        .insights-title {
            width: 55%;
            h2 {
                font-size: 46px;
                font-weight: 500;
                color: $dark;
            }
        }

        .insights-content {
            width: 45%;

            .insights-content-main{
                color: $dark;
                font-size: 18px;
                line-height: 1.3;
                padding-bottom: 15px;
            }

            a {
                text-decoration: none;
            }
        }
    }

    .normal-link-wrapper{
        display: flex;
        width: fit-content;
        position: relative;
        align-items: center;

        &:hover {
            p {
                margin-right: 20px;
                transition: all 0.2s ease;
            }
        }
        &::after {
            content: '';
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: $accent;
        }

        p{
            color: $dark;
            margin-right: 10px;
            font-size: 18px;
            font-weight: 5800;
            transition: all 0.2s ease;
        }

        img {
            width: 25px;
        }
    }
}

@media screen and (max-width: 768px) {
    .insights {
        padding: 40px 0;
    }

    .insights .inisghts-wrapper{
        flex-direction: column;
        padding-bottom: 40px;
    }

    .insights .inisghts-wrapper .insights-title{
        width: 100%;
        h2 {
            font-size: 36px;
            margin-bottom: 20px;
        }
    }

    .insights .inisghts-wrapper .insights-content{
        width: 100%;
        p {
            font-size: 16px;
        }
        
    }

    .insights .inisghts-wrapper .insights-content .insights-content-main{
        font-size: 16px;
    }
}