@import './variable.scss';

.services-content {
    width: 100%;
    background-color: $bg;
    padding: 0 0 90px 0;
    &-wrapper {
        .services-img {
            position: relative;
            width: 100%;
            height: 60vh;
            img {
                top: 0;
                left: 0;
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        
        .services-main-wrapper {
            padding: 60px 30px 0;

            .services-title {
                // padding: 0 30px;
                h1 {
                    font-size: 46px;
                    color: $dark;
                    font-weight: 400;
                }
            }

            .services-content-inner {
                // padding: 0 30px 0 0 ;
                p {
                    padding-bottom: 30px;
                    color: $dark;
                    font-size: 18px;
                    line-height: 1.3;
                }
            }
        }

        .services-features {
            width: 100%;

            .feature-boxes {
                display: flex;
                justify-content: space-between;
                padding-top: 60px;


                .feature-box {
                    &:hover {
                        p {
                            color: $white;
                        }

                        &::before {
                            bottom: 0;
                            transition: all 0.2s;
                        }
                        
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        bottom: -100%;
                        z-index: 0;
                        background-color: $dark;
                        transition: all 0.2s;

                        
                    }
                    transition: all 0.2s;
                    position: relative;
                    width: 30%;
                    overflow: hidden;
                    // border-radius: 10px;
                    background-color: $white;
                    padding: 30px 30px;
                    min-height: 300px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    cursor: pointer;
                    h2 {
                        color: $accent;
                        font-size: 24px;
                        font-weight: 500;
                        z-index: 1;
                        position: relative;
                        // padding-bottom: 120px;
                    }

                    p{
                        z-index: 1;
                        position: relative;
                        color: $dark;
                        // font-size: 14px;
                    }
                }
            }
        }
    }
}