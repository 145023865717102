@import './variable.scss';

.blogcard {
    background-color: $white;
    width: 100%;
    position: relative;
    overflow: hidden;
    &:hover {
        .blogcard-content p {
            color: #ffffff!important;
        }

        .blogcard-info h4 {
            color: #ffffff!important;
        }

        &::before {
            height: 100%;
        }
    }
    &::before {
        content: '';
        background-color: $dark;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0%;
        z-index: 0;
        transition: height 0.3s ease;
    }

    

    &-img {
        position: relative;
        img {
            width: 100%;
            z-index: 1;
        }
    }

    &-content {
        padding: 20px 30px;
        position: relative;
        p {
            font-size: 18px;
            line-height: 1.3;
            color: $dark;
            padding-bottom: 120px;
            transition: color 0.2s ease; /* Add transition here */
        }
    }

    .blogcard-cta {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .blogcard-info {
        h4 {
            margin-bottom: 5px;
            color: $dark;
            font-size: 16px;
            transition: color 0.2s ease; /* Add transition here */
        }
        p {
            font-size: 12px;
            padding-bottom: 0px;
            color: $dark;
            opacity: 0.4;
            font-weight: 600;
        }
    }

    .arrow-round {
        background-color: $accent;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: relative;
        img {
            position: absolute;
            transform: translate(-50%, -50%);
            left: 50%;
            top: 50%;
            margin: auto;
            width: 20px;
        }
    }
}

@media screen and (max-width: 768px){
    .blogcard-content p {
        font-size: 16px;
    }

    .blogcard {
        margin-bottom: 20px;
    }
}