@import './variable.scss';

.custom-title{
    width: 100%;
    padding: 90px 0 0 0;
    &-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        h2 {
            font-size: 46px;
            width: 50%;
            font-weight: 500;
            line-height: 1.15;
            color: $dark;
        }
    }
}

@media screen and (max-width: 768px) {
    .custom-title{
        width: 100%;
        padding: 40px 0 0 0;
        &-wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
    
            h2 {
                font-size: 32px;
                width: 100%;
                font-weight: 500;
                line-height: 1.15;
                color: $dark;
                margin-bottom: 20px;
            }
        }
    }
}