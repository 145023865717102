@import './variable.scss';

.whyus{
    padding: 90px 0;
    background-color: $dark;

    &-title {
        width: 50%;
        h2 {
            color: $bg;
            font-size: 46px;
            line-height: 1.1;
            font-weight: 400;
        }   
    }

    &-main {
        width: 80%;
        margin: 60px auto 0px auto;

        .whyus-boxes {
            display: flex;
            flex-direction: row;
            margin-bottom: 60px;

            .whyus-box {
                padding: 40px 40px 60px 40px;
                width: calc(100%/3);
                h2 {
                    font-size: 60px;
                    font-weight: 500;
                }
                span {
                    font-size: 20px;
                }
            }

            .box-one {
                background-color: #fff;
                h2 {
                    color: $dark;
                    display: flex;

                    span {
                        color: $dark;
                    }
                }

                p{
                    color: $dark;
                    line-height: 1.3;
                    font-weight: 500;
                    font-size: 18px;
                }
            }
            .box-two {
                background-color: #DCAD96;
                h2 {
                    color: $dark;
                    display: flex;
                    
                    span {
                        color: $dark;
                    }
                }

                p{
                    color: $dark;
                    line-height: 1.2;
                    font-weight: 600;
                    font-weight: 500;
                    font-size: 18px;
                }
            }

            .box-three {
                background-color: $accent;

                h2 {
                    color: $bg;
                    display: flex;
                    
                    span {
                        color: $dark;
                    }
                }

                p{
                    color: $bg;
                    line-height: 1.2;
                    font-weight: 600;
                    font-weight: 500;
                    font-size: 18px;
                }
            }
        }
    }
}

@media screen and (max-width: 768px){
    .whyus {
        padding: 40px 0;
    }

    .whyus-title {
        width: 100%;
        h2 {
            font-size: 32px;
        }
    }

    .whyus-main{
        width: 100%;
        margin: 40px auto 0px auto;
    }

    .whyus-main .whyus-boxes{
        flex-direction: column;
        margin-bottom: 40px;
    }

    .whyus-main .whyus-boxes .whyus-box{
        width: 100%;
        padding: 25px 25px 35px 25px;
    }

    .whyus-main .whyus-boxes .whyus-box h2{
        font-size: 42px;
    }

    .whyus-main .whyus-boxes .box-two p, .whyus-main .whyus-boxes .box-one p, .whyus-main .whyus-boxes .box-three p{
        font-size: 16px;
    }
}