@import './variable.scss';

.custom-button {
    // min-width: 200px;
    width: fit-content;
    padding: 9px 5px 9px 30px;
    background-color: $bg;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    // margin-right: 10px;
    p{
        color: $dark;
        font-size: 16px;
        font-weight: 500;
        text-transform: capitalize;
        // line-height: 3;
    }

    .custom-button-arrow {
        margin-left: 15px;
        width: 45px;
        height: 45px;
        padding: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: $accent;
        .arrow {
            width: 75%;
            margin: auto;
            object-fit: cover;
        }
    }
}

@media screen and (max-width: 768px) {
    
}